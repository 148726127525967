import {
  CHANGE_LANGUAGE_EVENT,
  CHANGE_LEVEL_EVENT,
  ENVIRONMENT,
  LOGOUT_EVENT,
  REQUEST_PUSH_PERMISSION_EVENT,
  SHOW_MODAL_EVENT,
  SHOW_MODAL_REQUEST_EVENT,
  QUERY_STRING_REDIRECT,
  LOCAL_STORAGE_REDIRECT
} from "./config";
import {
  InicializeAmplitude,
  InicializeBraze,
  RequestPushPermissionBraze,
  addGoogleAnalytics,
  addGoogleOptimize,
  addGoogleTagManagerBody,
  addGoogleTagManagerHead,
  createDataLayer,
  getFirebaseVariant,
  inicializeFirebase,
  setFirebaseProperties
} from "./third_party";
import { addDependencyToHead, getTranslation, getUrlParameter, hasUrlParameter, removeUnusedDependenciesFromHead } from "./utils";
import {
  cleanAccessToken,
  getFallbackMicrofrontends as getFallbackMicrofrontendsFromRepository,
  getMicrofrontends as getMicrofrontendsFromRepository,
  getPermissions,
  getTranslations,
  getUser,
  initializeOauth
} from "./aplication/repository";

import Navigo from "navigo"
import isMobile from 'ismobilejs';
import { urlsMicrofrontsEnv } from "./dictionary";

const env = urlsMicrofrontsEnv (ENVIRONMENT)
const isTestEnvironment = process.env.NODE_ENV === 'test'

function clearLocalStorage () {
  const localStorageItems = ['playedTeacherTips', 'finishedLevelPopups']
  localStorageItems.forEach(item => localStorage.removeItem(item))
}

function generateUUID() {
  var d = new Date().getTime();
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16;
      if(d > 0){
          r = (d + r)%16 | 0;
          d = Math.floor(d/16);
      } else {
          r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

function redirectToLogin () {
  cleanAccessToken()
    .then(() => {
      const key = generateUUID()
      const redirectUrl = window.location.href
      window.localStorage.setItem(LOCAL_STORAGE_REDIRECT, JSON.stringify({ key, redirectUrl }))
      window.location.href = `/login?${QUERY_STRING_REDIRECT}=${key}`
    })
}

export function createRoutes (microfrontends, variant, oauthPass) {
  let routes = {};
  const microfrontendRoutes = getMicrofrontendRoutes(microfrontends)
  const barMicrofrontends = getBarMicrofrontends(microfrontends)
  const modalsMicrofrontend = getModalsMicrofrontend(microfrontends)
  microfrontendRoutes.forEach(function(item) {
    routes[`${item.path}`] = async function() {
      const user = JSON.parse( sessionStorage.user || '{}' );
      const translations = JSON.parse( sessionStorage.translations || '{}' );
      manageBarVisibility('mf-leftbar', item.leftbar);
      manageBarVisibility('mf-topbar', item.topbar);
      unmountMicrofrontend(window.currentMicrofrontend, 'root');
      if (window.currentMicrofrontend !== item.name) {
        removeUnusedDependenciesFromHead(window.currentMicrofrontend);
      }
      if (item.showSplashScreen && isMobile(window.navigator).phone) {
        fireModalEvent(modalsMicrofrontend, user, oauthPass, variant, translations, 'SplashScreen')
      } else {
        if (item.leftbar || item.topbar) {
          loadBarsMicrofrontends(barMicrofrontends, user, oauthPass, variant, translations);
        }
        await addDependencyToHead(item.name, item.css, item.js, function() {
          const renderResponse = renderMicrofrontend(item.name,'root', user, oauthPass, variant, translations);
          window.currentMicrofrontend = item.name;
          window.router = renderResponse && renderResponse.router;
          const event = new Event('CHANGE_URL')
          document.dispatchEvent(event)
        });
      }
  };
  });
  return routes;
}

function fireModalEvent (modalsMicrofrontend, user, oauthPass, variant, translations, modalName, language, modalProps) {
  Document.prototype.realAddEventListener = HTMLAnchorElement.prototype.addEventListener;

  Document.prototype.addEventListener = function(type, listener, options){
      if (type === SHOW_MODAL_EVENT) {
        window.showModalEventListenerIsReady = true
      }
      this.realAddEventListener(type, listener, options)
  }

  loadModalsMicrofrontend(modalsMicrofrontend, user, oauthPass, variant, translations, language)
    .then(() => {
      const fireEventWhenRendered = () => {
        setTimeout(() => {
          if (window[modalsMicrofrontend.modals.name] && window.showModalEventListenerIsReady) {
            const customEvent = new CustomEvent(SHOW_MODAL_EVENT, { detail: { modalName, modalProps } })
            document.dispatchEvent(customEvent)
          } else {
            fireEventWhenRendered()
          }
        }, 200)
      }
      fireEventWhenRendered()
    })
}

async function loadAndRenderMicrofrontend (microfrontend, key, domNodeId, user, oauthPass, variant, translations, language) {
  const render = () => {
    renderMicrofrontend(microfrontend.name, domNodeId, user, oauthPass, variant, translations, language);
  }
  if (!window[microfrontend.name]) {
    await addDependencyToHead(key, microfrontend.css, microfrontend.js, function() {
      render();
    });
  } else {
    render();
  }
}

export async function loadBarsMicrofrontends (barMicrofrontends, user, oauthPass, variant, translations) {
  const { topbar, leftbar } = barMicrofrontends
  await loadAndRenderMicrofrontend(leftbar, 'leftbar', 'mf-leftbar', user, oauthPass, variant, translations)
  await loadAndRenderMicrofrontend(topbar, 'topbar', 'mf-topbar', user, oauthPass, variant, translations)
}

export async function loadModalsMicrofrontend (modalsMicrofrontend, user, oauthPass, variant, translations, language) {
  const { modals } = modalsMicrofrontend
  await loadAndRenderMicrofrontend(modals, 'modals', 'mf-modals', user, oauthPass, variant, translations, language)
}

function manageBarVisibility (domNodeId, status) {
  document.getElementById( domNodeId ).style.display = `${status ===true ? '' : 'none'}`;
}

function renderMicrofrontend (microfrontendName, domNodeId, user, oauthPass, variant, translations, language) {
  return window[microfrontendName].render({
    domNode: document.getElementById(domNodeId),
    oauthPass,
    user,
    variant,
    translations,
    isMobile: isMobile(window.navigator).phone,
    language
  });
}

function unmountMicrofrontend (microfrontendName, domNodeId) {
  if (microfrontendName && window[microfrontendName] && window[microfrontendName].unmount) {
    window[microfrontendName].unmount({domNode: document.getElementById(domNodeId)});
    window[microfrontendName] = undefined;
  }
}

function initializeRouter (microfrontends, variant, oauthPass) {
  const mainRouter = new Navigo('/')
  mainRouter.on(createRoutes(microfrontends, variant, oauthPass))
  mainRouter.notFound(()=> { window.location.href = '/'})
  mainRouter.resolve()
  window.mainRouter = mainRouter
  window.mainRouter.addUniqueBeforeHook = (path, fn) => {
  const route = window.mainRouter.getRoute(path)
    const beforeHooks = route?.hooks?.before
    if (beforeHooks) {
      const foundHook = beforeHooks.find(hook => hook.toString() === fn.toString())
      if (foundHook) return
    }
    window.mainRouter.addBeforeHook(path, fn)
  }
}

function getLanguageFromBrowser() {
  try {
    return (navigator.language || navigator.userLanguage).split('-')[0].toLowerCase()
  } catch {}
}

function getLanguage () {
  const languageParameter = (getUrlParameter('language') || getLanguageFromBrowser() || 'en').toLowerCase()
  const supportedLanguages = ['es', 'en', 'it', 'fr', 'pt', 'de', 'ru', 'tr', 'zh']
  const supportedLanguage = supportedLanguages.includes(languageParameter) ? languageParameter : 'en'
  return supportedLanguage
}

async function getUserAndPermissions () {
  const user = await getUser()
  const permissions = await getPermissions()
  return { ...user, ...permissions }
}

function redirectAfterLogin () {
  try {
    const storedRedirect = window.localStorage.getItem("aba_redirect_url")
    if (storedRedirect) {
      const { key, redirectUrl } = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_REDIRECT))
      const queryStringRedirectKey = getUrlParameter(QUERY_STRING_REDIRECT)
      if (queryStringRedirectKey && key === queryStringRedirectKey) {
        window.localStorage.removeItem("aba_redirect_url")
        window.location.href = redirectUrl
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export async function initInfrastructure () {
  const oauthPass = await initializeOauth()
  getMicrofrontends()
    .then((microfrontends) => {
      const firebaseApp = inicializeFirebase()
      const modalsMicrofrontend = getModalsMicrofrontend(microfrontends)
      getFirebaseVariant()
        .then((variant) => {
          if (variant && variant.firebase_web_under_maintenance && variant.firebase_web_under_maintenance._value === 'true') {
            const language = getLanguage()
            getTranslations(language)
              .then((translations) => {
                fireModalEvent(modalsMicrofrontend, {}, oauthPass, {}, translations, 'UnderMaintenance')
              })
          } else {
            PreloadPlansPage(microfrontends)
            if (window.location.pathname.startsWith('/login')) {
              const language = getLanguage()
              document.title = language === 'es' ? 'Acceso al curso - ABA English' : 'Login to the course - ABA English'
              getTranslations(language)
                .then((translations) => {
                  fireModalEvent(modalsMicrofrontend, {}, oauthPass, {}, translations, 'Login', language)
                })
            } else if (window.location.pathname.toLowerCase().startsWith('/exchangecoupon')) {
              const language = getLanguage()
              getTranslations(language)
                .then((translations) => {
                  fireModalEvent(modalsMicrofrontend, {}, oauthPass, {}, translations, 'ExchangeCoupon', language)
                })
            } else {
              getUserAndPermissions()
                .then((user) => {
                  sessionStorage.user = JSON.stringify(user)
                  redirectAfterLogin()
                  getTranslations(user.language)
                    .then((translations) => {
                      InicializeBraze(user)
                      setFirebaseProperties(firebaseApp, user)
                      initializeRouter(microfrontends, variant, oauthPass)
                      createDataLayer(user)
                      InicializeAmplitude(user).then(() => trackCampaign())
                      addSmartBanner(translations)
                      addGoogleTagManagerHead()
                      addGoogleTagManagerBody()
                      addGoogleOptimize()
                      addGoogleAnalytics()
                      if (!user.isPremium && hasUrlParameter('abafancode')) {
                        fireModalEvent(modalsMicrofrontend, {}, oauthPass, {}, translations, 'FanCode')
                      }
                      window.showModal = (modalName, modalProps) => {
                        fireModalEvent(modalsMicrofrontend, {}, oauthPass, {}, translations, modalName, user.language, modalProps)
                      }
                    })
                })
                .catch(() => {
                  logout()
                })
            }
          }
      })
    })
}

export async function addEventListeners () {
  document.addEventListener(CHANGE_LANGUAGE_EVENT, ({ detail }) => {
    RefreshPageWith(detail.language);
  })

  document.addEventListener(CHANGE_LEVEL_EVENT, ({ detail }) => {
    const user = JSON.parse(sessionStorage.user);
    user.level = detail.level;
    sessionStorage.user = JSON.stringify(user);
  })

  document.addEventListener(REQUEST_PUSH_PERMISSION_EVENT, () => {
    RequestPushPermissionBraze();
  })

  document.addEventListener(LOGOUT_EVENT, () => {
    logout()
  })

  document.addEventListener(SHOW_MODAL_REQUEST_EVENT, ({ detail }) => {
    window.showModal(detail.modalName, detail.modalProps)
  })
}

function RefreshPageWith(language){
  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);
  if (params.has('language')) {
    params.delete('language')
  }
  window.location.search += `&language=${language}`;
}

function logout () {
  clearLocalStorage()
  !isTestEnvironment && redirectToLogin()
}

async function PreloadPlansPage (microfrontends) {
  const { key, version } = microfrontends.find((mf) => mf.key === 'academy-payment')
  if (key) {
    var preloadStyle = document.createElement('link');
    preloadStyle.href = `https://frontend${env}/${key}/main.css?v=${version}`;
    preloadStyle.rel = 'preload';
    preloadStyle.as = 'style';
    document.head.appendChild(preloadStyle);
    
    var preloadScript = document.createElement('link');
    preloadScript.href = `https://frontend${env}/${key}/main.js?v=${version}`;
    preloadScript.rel = 'preload';
    preloadScript.as = 'script';
    document.head.appendChild(preloadScript);
  }
}

export async function getMicrofrontends () {
  let microfrontends = await getMicrofrontendsFromRepository()
  if (!checkMicrofrontends(microfrontends)) {
    microfrontends = getFallbackMicrofrontends()
  }
  return microfrontends
}

export function getFallbackMicrofrontends () {
  return getFallbackMicrofrontendsFromRepository()
}

function checkMicrofrontends (microfrontends) {
  return Array.isArray(microfrontends) && microfrontends.length > 0
}

export function getMicrofrontendRoutes (microfrontends) {
  const routes = microfrontends
    .map(({ key, version, name, routes }) => (
    routes && routes.map(({ path, leftbar, topbar, showSplashScreen }) => ({
      path,
      name,
      js: `https://frontend${env}/${key}/main.js?v=${version}`,
      css: `https://frontend${env}/${key}/main.css?v=${version}`,
      leftbar,
      topbar,
      showSplashScreen
    }))
  ))
  return (routes.filter(item => (!!item)).flat())
}

export function getBarMicrofrontends (microfrontends) {
  return {
    leftbar: parseMicrofontend(microfrontends.find(mf => mf.key === 'academy-leftbar')),
    topbar: parseMicrofontend(microfrontends.find(mf => mf.key === 'academy-topbar'))
  }
}

export function getModalsMicrofrontend (microfrontends) {
  return {
    modals: parseMicrofontend(microfrontends.find(mf => mf.key === 'academy-modals'))
  }
}

function parseMicrofontend (microfrontend) {
  return {
    name: microfrontend.name,
    js: `https://frontend${env}/${microfrontend.key}/main.js?v=${microfrontend.version}`,
    css: `https://frontend${env}/${microfrontend.key}/main.css?v=${microfrontend.version}`
  }
}

async function addSmartBanner (translations) {
  const pathName = window.location.pathname
  if (pathName !== '/plans') {
    Object.assign(window.smartbanner.options, {
      title: getTranslation(translations, 'smartbanner_title'),
      author: getTranslation(translations, 'smartbanner_author'),
      price: getTranslation(translations, 'smartbanner_price'),
      priceSuffixApple: getTranslation(translations, 'smartbanner_price_suffix_apple'),
      priceSuffixGoogle: getTranslation(translations, 'smartbanner_price_suffix_google'),
      button: getTranslation(translations, 'smartbanner_button'),
      closeLabel: getTranslation(translations, 'smartbanner_close'),
      iconApple: 'https://static.abaenglish.com/academy/Favico/apple-touch-icon.png',
      iconGoogle: 'https://static.abaenglish.com/academy/Favico/android-chrome-192x192.png',
      buttonUrlApple: 'https://apps.apple.com/es/app/aba-english-aprender-ingl%C3%A9s/id859243872',
      buttonUrlGoogle: 'https://play.google.com/store/apps/details?id=com.abaenglish.videoclass&hl=es_419&gl=US',
      enabledPlatforms: 'android,ios'
    })
    window.smartbanner.publish();
  }
}

async function trackCampaign() {
  const isDynamic = getUrlParameter('dynamic') === '1'
  if (!isDynamic) return

  let dynamicLinkTracking
  const params = ['source', 'campaign_name', 'campaign_message', 'origin', 'type']
  const values = params.map(getUrlParameter)

  const initialObject = { url: window.location.href }

  dynamicLinkTracking = params.reduce((acc, key, index) => {
    acc[key] = values[index]
    return acc
  }, initialObject)

  window.amplitude.logEvent('clicked_dynamic_link', dynamicLinkTracking)
}
